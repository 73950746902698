import { BOT_TOKEN, CHAT_ID_PARTNERS, CHILD_TYPE_LINK, CHILD_TYPE_LIST_ITEM, CHILD_TYPE_TEXT } from "./constants";

export function getRandomId() {
  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
  return ObjectId();
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const rgbToHex = (r, g, b) => {
  return "#" + [r, g, b].map(x => {
    const hex = x.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }).join("");
};

export const interpolateColor = (color1, color2, factor = 0.5) => {
  const color1Rgb = hexToRgb(color1);
  const color2Rgb = hexToRgb(color2);

  const result = {
    r: Math.round(color1Rgb.r + factor * (color2Rgb.r - color1Rgb.r)),
    g: Math.round(color1Rgb.g + factor * (color2Rgb.g - color1Rgb.g)),
    b: Math.round(color1Rgb.b + factor * (color2Rgb.b - color1Rgb.b))
  };

  return rgbToHex(result.r, result.g, result.b);
};

export function renderArticleChildren(children) {
  return children.map((child, idx) => {
    let childElement = null;

    // Обработка обычного текста
    if (child.type === CHILD_TYPE_TEXT && !child?.text?.includes('<b>')) {
      childElement = (
        <span
          className={`${child.bold ? "bold" : ""}${child.italic ? "italic" : ""
            }${child.underline ? "underline" : ""}${child.strikethrough ? "strikethrough" : ""
            }${child.goodTitle ? "goodTitle" : ""}${child.badTitle ? "badTitle" : ""
            } ${child.goodTitle || child.badTitle ? 'vars-element__text_center' : ''}`}
          key={idx}
        >
          {child.text}
        </span>
      );
    }

    // Обработка 
    if ((child.type === CHILD_TYPE_TEXT && child.text && child.text?.includes('<b>'))
      || child?.text?.includes('</br>')
    ) {
      childElement = (
        <span
          key={idx}
          dangerouslySetInnerHTML={{ __html: child.text }}
        />
      );
    }

    //Обработка булет списка
    if ((child.type === CHILD_TYPE_TEXT && child?.text?.includes('<li>') &&
      child?.text?.includes('<ul>')) || child?.text?.includes('</ul>')
    ) {
      childElement = (
        <ul
          dangerouslySetInnerHTML={{ __html: child.text }}
        />
      );
    }

    //Обработка нумер списка
    if ((child.type === CHILD_TYPE_TEXT && child?.text?.includes('<li>') &&
      child?.text?.includes('<ol>')) || child?.text?.includes('</ol>')
    ) {
      childElement = (
        <ol
          dangerouslySetInnerHTML={{ __html: child.text }}
        />
      );
    }

    // Обработка ссылок
    if (child.type === CHILD_TYPE_LINK && child.children) {
      childElement = (
        <a
          className={`link ${child.bold ? "bold" : ""}${child.italic ? "italic" : ""
            }${child.underline ? "underline" : ""}${child.strikethrough ? "strikethrough" : ""
            }`}
          key={idx}
          href={child.url}
          target="_blank"
          rel="noreferrer"
        >
          {renderArticleChildren(child.children)}
        </a>
      );
    }

    // Обработка элементов списка
    if (child.type === CHILD_TYPE_LIST_ITEM && child.children) {
      childElement = renderArticleChildren(child.children);
    }

    return childElement;
  });
};

export function compareOptions(array1, array2) {
  const result = {
    matches: [],
    uniqueInArray1: [],
    uniqueInArray2: [],
    differences: [] // Objects with the same name but different values
  };
  const mapArray2 = new Map(array2.map(item => [item.name, item]));
  array1.forEach(item1 => {
    const item2 = mapArray2.get(item1.name);
    if (item2) {
      if (item1.value === item2.value) {
        result.matches.push(item1);
      } else {
        result.differences.push({ name: item1.name, value1: item1.value, value2: item2.value });
      }
      mapArray2.delete(item1.name); // Remove matched item to find unique items in array2 later
    } else {
      result.uniqueInArray1.push(item1);
    }
  });
  result.uniqueInArray2 = Array.from(mapArray2.values());
  return result;
}

export const sendTextTgBot = async (chatId, sendText, setPreloaders) => {
  const text = sendText;
  try {
    const response = await fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(text)}`);
    const data = await response.json();
    console.log(data);
    // Handle success (e.g., clear form, show success message)
  } catch (error) {
    console.error('Error:', error);
    // Handle error
  }
}

export const getUTMHistoryString = () => {
  const utmHistory = JSON.parse(localStorage.getItem('utm_history')) || [];
  return utmHistory
    .map((utmObj) => {
      return Object.entries(utmObj)
        .map(([key, value]) => {
          if (key === 'date') {
            // Convert ISO date string to a UTC format
            const formattedDate = new Date(value).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              hour12: false,
              timeZone: 'UTC',
            });
            return `${key}: ${formattedDate} UTC`;
          }
          return `${key}: ${value}`;
        })
        .join(', ');
    })
    .join('\n');
};